var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"mainForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-container',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',[_c('v-data-table',{attrs:{"headers":_vm.claimProductHeaders,"items":_vm.localClaimProducts
                    ? _vm.localClaimProducts.filter(function (cp) { return cp.promotionProduct.product.productKey != 'TRADE_IN_PRODUCT'; })
                    : null,"item-class":_vm.itemBackgroundColor,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.promotionProduct.product.productKey",fn:function(ref){
                    var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('span',{staticClass:"productKeyField"},[_vm._v(_vm._s(item.promotionProduct.product.productKey))])]),(_vm.askForPurchaseDate || _vm.askForPurchasePrice)?_c('v-col',{attrs:{"cols":12}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 ml-2",on:{"click":function($event){return _vm.onDeleteProduct(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Delete Product")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 ml-2",on:{"click":function($event){return _vm.onDuplicateProduct(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-content-duplicate")])]}}],null,true)},[_c('span',[_vm._v("Duplicate Product")])])],1):_vm._e()],1)]}},{key:"item.serialNumber",fn:function(ref){
                    var item = ref.item;
return [_c('v-row',{staticClass:"py-2"},[(!item.promotionProduct.hideSerialNumber)?_c('v-col',{attrs:{"cols":12,"xxl":8}},[(item.promotionProduct.enableSerialNumbers)?_c('v-text-field',{class:item.promotionProduct.enableSerialNumbers ? 'required mt-2' : 'mt-2',staticStyle:{"width":"calc(1ch * 18)"},attrs:{"outlined":"","dense":"","background-color":item.ocrPopulated ? '#bbdefb' : null,"label":_vm.$i18n.translate('Serial Number'),"prefix":"S01 -","rules":item.promotionProduct.enableSerialNumbers
                            ? [_vm.requireSerialNumber(item), _vm.checkDuplicatedSerialNumber(item)]
                            : [],"disabled":!item.promotionProduct.enableSerialNumbers,"readonly":!item.promotionProduct.enableSerialNumbers},on:{"keydown":function($event){return _vm.keyHandler($event)},"input":function($event){item.ocrPopulated = false}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.flags.includeClaimProductsSerialNumberTooltip)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function () {}}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.translate(_vm.strings.claimProductsSerialnumberTooltipText))+" ")])]):_vm._e()]},proxy:true}],null,true),model:{value:(item.serialNumber),callback:function ($$v) {_vm.$set(item, "serialNumber", $$v)},expression:"item.serialNumber"}}):_c('v-chip',{staticClass:"mt-3",attrs:{"label":""}},[_vm._v(" "+_vm._s(_vm.$i18n.translate("Serial Number"))+": N/A ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":12,"xxl":6}},[(!item.promotionProduct.hideQuantity)?_c('v-row',[(!item.promotionProduct.enableSerialNumbers)?_c('v-text-field',{class:!item.promotionProduct.enableSerialNumbers ? 'required mt-2' : 'mt-2',staticStyle:{"width":"calc(1ch * 9)"},attrs:{"outlined":"","dense":"","label":"Quantity","type":"number","min":"1","max":"9999999","readonly":item.promotionProduct.enableSerialNumbers,"disabled":item.promotionProduct.enableSerialNumbers,"rules":!item.promotionProduct.enableSerialNumbers ? _vm.localRules.quantity : '',"background-color":item.ocrPopulated ? '#bbdefb' : null},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}}):_c('v-chip',{staticClass:"mt-3",attrs:{"label":""}},[_vm._v(" Quantity: 1 ")])],1):_vm._e(),(
                          item &&
                            item.promotionProduct &&
                            item.promotionProduct.enableCustomField1 &&
                            _vm.selectedProgram &&
                            _vm.selectedProgram.claimProductCustomFields &&
                            _vm.selectedProgram.claimProductCustomFields[0]
                        )?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[(
                              item &&
                                item.promotionProduct &&
                                item.promotionProduct.enableCustomField1 &&
                                _vm.selectedProgram &&
                                _vm.selectedProgram.claimProductCustomFields &&
                                _vm.selectedProgram.claimProductCustomFields[0]
                            )?_c('CustomField',{attrs:{"customField":_vm.selectedProgram.claimProductCustomFields[0]},model:{value:(item.customFieldValue1),callback:function ($$v) {_vm.$set(item, "customFieldValue1", $$v)},expression:"item.customFieldValue1"}}):_vm._e()],1)],1):_vm._e(),(
                          item &&
                            item.promotionProduct &&
                            item.promotionProduct.enableCustomField2 &&
                            _vm.selectedProgram &&
                            _vm.selectedProgram.claimProductCustomFields &&
                            _vm.selectedProgram.claimProductCustomFields[1]
                        )?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('CustomField',{attrs:{"customField":_vm.selectedProgram.claimProductCustomFields[1]},model:{value:(item.customFieldValue2),callback:function ($$v) {_vm.$set(item, "customFieldValue2", $$v)},expression:"item.customFieldValue2"}})],1)],1):_vm._e(),(
                          item &&
                            item.promotionProduct &&
                            item.promotionProduct.enableCustomField3 &&
                            _vm.selectedProgram &&
                            _vm.selectedProgram.claimProductCustomFields &&
                            _vm.selectedProgram.claimProductCustomFields[2]
                        )?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('CustomField',{attrs:{"customField":_vm.selectedProgram.claimProductCustomFields[2]},model:{value:(item.customFieldValue3),callback:function ($$v) {_vm.$set(item, "customFieldValue3", $$v)},expression:"item.customFieldValue3"}})],1)],1):_vm._e(),(
                          item &&
                            item.promotionProduct &&
                            item.promotionProduct.enableCustomField4 &&
                            _vm.selectedProgram &&
                            _vm.selectedProgram.claimProductCustomFields &&
                            _vm.selectedProgram.claimProductCustomFields[3]
                        )?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('CustomField',{attrs:{"customField":_vm.selectedProgram.claimProductCustomFields[3]},model:{value:(item.customFieldValue4),callback:function ($$v) {_vm.$set(item, "customFieldValue4", $$v)},expression:"item.customFieldValue4"}})],1)],1):_vm._e(),(
                          item &&
                            item.promotionProduct &&
                            item.promotionProduct.enableCustomField5 &&
                            _vm.selectedProgram &&
                            _vm.selectedProgram.claimProductCustomFields &&
                            _vm.selectedProgram.claimProductCustomFields[4]
                        )?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('CustomField',{attrs:{"customField":_vm.selectedProgram.claimProductCustomFields[4]},model:{value:(item.customFieldValue5),callback:function ($$v) {_vm.$set(item, "customFieldValue5", $$v)},expression:"item.customFieldValue5"}})],1)],1):_vm._e(),(
                          item &&
                            item.promotionProduct &&
                            item.promotionProduct.enableCustomField6 &&
                            _vm.selectedProgram &&
                            _vm.selectedProgram.claimProductCustomFields &&
                            _vm.selectedProgram.claimProductCustomFields[5]
                        )?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('CustomField',{attrs:{"customField":_vm.selectedProgram.claimProductCustomFields[5]},model:{value:(item.customFieldValue6),callback:function ($$v) {_vm.$set(item, "customFieldValue6", $$v)},expression:"item.customFieldValue6"}})],1)],1):_vm._e(),(
                          item &&
                            item.promotionProduct &&
                            item.promotionProduct.enableCustomField7 &&
                            _vm.selectedProgram &&
                            _vm.selectedProgram.claimProductCustomFields &&
                            _vm.selectedProgram.claimProductCustomFields[6]
                        )?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('CustomField',{attrs:{"customField":_vm.selectedProgram.claimProductCustomFields[6]},model:{value:(item.customFieldValue7),callback:function ($$v) {_vm.$set(item, "customFieldValue7", $$v)},expression:"item.customFieldValue7"}})],1)],1):_vm._e(),(
                          item &&
                            item.promotionProduct &&
                            item.promotionProduct.enableCustomField8 &&
                            _vm.selectedProgram &&
                            _vm.selectedProgram.claimProductCustomFields &&
                            _vm.selectedProgram.claimProductCustomFields[7]
                        )?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('CustomField',{attrs:{"customField":_vm.selectedProgram.claimProductCustomFields[7]},model:{value:(item.customFieldValue8),callback:function ($$v) {_vm.$set(item, "customFieldValue8", $$v)},expression:"item.customFieldValue8"}})],1)],1):_vm._e(),(
                          item &&
                            item.promotionProduct &&
                            item.promotionProduct.enableCustomField9 &&
                            _vm.selectedProgram &&
                            _vm.selectedProgram.claimProductCustomFields &&
                            _vm.selectedProgram.claimProductCustomFields[8]
                        )?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('CustomField',{attrs:{"customField":_vm.selectedProgram.claimProductCustomFields[8]},model:{value:(item.customFieldValue9),callback:function ($$v) {_vm.$set(item, "customFieldValue9", $$v)},expression:"item.customFieldValue9"}})],1)],1):_vm._e(),(
                          item &&
                            item.promotionProduct &&
                            item.promotionProduct.enableCustomField10 &&
                            _vm.selectedProgram &&
                            _vm.selectedProgram.claimProductCustomFields &&
                            _vm.selectedProgram.claimProductCustomFields[9]
                        )?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('CustomField',{attrs:{"customField":_vm.selectedProgram.claimProductCustomFields[9]},model:{value:(item.customFieldValue10),callback:function ($$v) {_vm.$set(item, "customFieldValue10", $$v)},expression:"item.customFieldValue10"}})],1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":item.promotionProduct.allowPriceEntry ? 5 : 0}},[(item.promotionProduct.allowPriceEntry)?_c('CurrencyField',{class:_vm.flags.markPurchasePriceAsRequired ? 'required mt-2' : 'mt-2',staticStyle:{"width":"calc(1ch * 14)"},attrs:{"outlined":"","dense":"","label":"Purchase Price","rules":_vm.rules.claimProductsPriceAmount
                            ? _vm.rules.claimProductsPriceAmount
                            : [function (v) { return !!v || 'Price is required'; }],"country":_vm.promotion.country},on:{"input":function($event){return _vm.setCleanPrice($event, item)}},model:{value:(item.priceAmount),callback:function ($$v) {_vm.$set(item, "priceAmount", $$v)},expression:"item.priceAmount"}}):_vm._e()],1)],1)]}},{key:"item.purchaseData",fn:function(ref){
                            var item = ref.item;
return [_c('v-row',{staticClass:"py-2"},[_c('v-col',{attrs:{"cols":12}},[(item.promotionProduct.allowProductPriceEntry)?_c('CurrencyField',{class:item.promotionProduct.allowProductPriceEntry ? 'required mt-2' : 'mt-2',staticStyle:{"width":"calc(1ch * 14)"},attrs:{"outlined":"","dense":"","label":"Price","rules":item.promotionProduct.allowProductPriceEntry ? [function (v) { return !!v || 'Price is required'; }] : [],"country":_vm.promotion.country},on:{"input":function($event){return _vm.setCleanPrice($event, item)}}}):_vm._e()],1),(_vm.askForPurchaseDate)?_c('v-col',{attrs:{"cols":12}},[_c('DateTimePickerField',{staticStyle:{"width":"calc(1ch * 20)"},attrs:{"label":"Purchase Date","rules":_vm.rules.claimProductsPurchaseDate
                            ? _vm.rules.claimProductsPurchaseDate
                            : [function (v) { return !!v || 'Purchase Date is required'; }],"required":true,"format":"MM/DD/YYYY","defaultTime":"00:00","onlyDate":""},model:{value:(item.purchaseDate),callback:function ($$v) {_vm.$set(item, "purchaseDate", $$v)},expression:"item.purchaseDate"}})],1):_vm._e()],1)]}},(!_vm.askForPurchaseDate && !_vm.askForPurchasePrice)?{key:"item.actions",fn:function(ref){
                            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 ml-2",on:{"click":function($event){return _vm.onDeleteProduct(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Delete Product")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 ml-2",on:{"click":function($event){return _vm.onDuplicateProduct(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-content-duplicate")])]}}],null,true)},[_c('span',[_vm._v("Duplicate Product")])])]}}:null],null,true)})],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',[_c('v-autocomplete',{ref:"menu",staticClass:"required",attrs:{"dense":"","placeholder":_vm.strings.productListText,"outlined":"","items":_vm.promotion && _vm.promotion.promotionProducts ? _vm.sortedProductsAlphabetically(_vm.promotion.promotionProducts) : [],"label":"Add a Product","item-value":"id","item-text":function (item) {
              return item && item.product ? item.product.productKey : '';
            },"multiple":"","return-object":"","rules":[_vm.requireProduct],"hint":"You must select at least 1 product for this claim","attach":"","auto":"","search-input":_vm.searchInput,"menu-props":{ zIndex: '9999', maxHeight: '270' }},on:{"input":function($event){_vm.searchInput = null},"update:searchInput":function($event){_vm.searchInput=$event},"update:search-input":function($event){_vm.searchInput=$event}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-slide-x-reverse-transition',{attrs:{"mode":"out-in"}},[_c('v-btn',{staticClass:"primary mt-n2",on:{"click":function($event){_vm.onAddProduct();
                  _vm.closeMenu();}}},[_vm._v("Add")])],1)]},proxy:true}]),model:{value:(_vm.selectedPromotionProducts),callback:function ($$v) {_vm.selectedPromotionProducts=$$v},expression:"selectedPromotionProducts"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }