<template>
  <div>
    <v-dialog v-model="loadingOcrInvoiceScan" max-width="400" persistent>
      <v-card class="elevation-12">
        <v-toolbar dark color="info">
          <v-toolbar-title>
            <span> {{ $i18n.translate("Auto-filling Your Claim!") }} </span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="text-center pt-5">
          <p>
            {{
              $i18n.translate(
                "Please wait while we read your invoice and attempt to auto-fill your claim! It can take over a minute to read your invoice."
              )
            }}
          </p>
          <v-progress-circular indeterminate color="primary" />
          <br /><br />
          <v-btn class="primary" @click="onCancelOcrScan">
            {{ $i18n.translate("Cancel and Manually Enter Details") }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container>
      <slot name="alert" />
      <ApiError :errors="apiErrors" v-if="apiErrors" />
      <v-row>
        <v-col cols="12" sm="12" md="9" lg="9" xl="9" class="pa-7">
          <v-form ref="uploadForm" @submit.prevent="$emit('nextStep')" v-if="!isBusy" v-model="valid.uploadForm">
            <h3 class="pb-3">Upload your Invoice</h3>
            <UploadField
              outlined
              v-model="localClaimUploads"
              :rules="[requireUpload]"
              :limitNumberFile="1"
              :public="isPublic"
              :allowedfileExtensions="['jpg', 'jpeg', 'png', 'pdf', 'heic']"
              :uploadOnBehalfOfParticipantId="isPublic ? null : selectedParticipant.id"
              :disabled="hasAlert"
              @change="$event != null ? showLoadingOcrInvoiceScan() : null"
              @newUpload="ocrInvoice($event)"
              @input="$refs.uploadForm.validate()"
              @uploadError="handleUploadError"
              v-if="isPublic || (selectedParticipant && localClaimUploads)"
              message="Click here to upload your invoice. "
            />
            <div class="text-center">
              <v-btn color="primary" :disabled="!valid.uploadForm || hasAlert" @click="$emit('nextStep')" class="mr-2">
                {{ $i18n.translate("Continue") }}
              </v-btn>
              <v-btn @click="$emit('cancel')" text>
                {{ $i18n.translate("Cancel") }}
              </v-btn>
            </div>
          </v-form>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="grey lighten-3 pa-7">
          <h3 class="pb-2"><v-icon color="primary">mdi-information-outline</v-icon> Submitting Invoices</h3>
          <ul>
            <li class="pt-2 pb-2">
              Please upload your invoice, which needs to contain products and serial numbers (even if hand-written). We
              will attempt to auto-fill your claim form, but ask that you review and correct any errors, as mistakes do
              happen.
            </li>
            <li class="pt-2 pb-2">
              You will be able to upload additional supporting documentation later, but this will impact your claim's
              processing time.
            </li>
            <li class="pt-2 pb-2">
              Refer to the Promotion for proper documentation requirements and all Terms and Conditions.
              <ul>
                <li v-for="(upload, i) in uploads" :key="i">
                  <a :href="upload.href" target="_blank" rel="noreferrer">
                    {{ upload.originalFilename }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import UploadField from "@/gapp-components/components/fields/UploadField.vue";
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  components: { UploadField, ApiError },
  name: "OcrStep",
  props: {
    public: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loadingOcrInvoiceScan: false,
    isBusy: false,
    ocrAttempts: 0,
    ocrMaxAttempts: 36,
    ocrPromise: null,
    apiErrors: null,
    valid: {
      uploadForm: false
    },
    uploads: {},
    localClaimFieldValues: [],
    localClaimFields: [],
    localClaimProducts: [],
    localClaimUploads: { existing: [], deleted: [] },
    localAvailableAddresses: [],
    ocrInvoiceScanToClaimFieldMappings: [
      {
        property: "invoiceReceiptDate",
        claimFieldTypeName: "INVOICE_DATE_TYPE",
        convert: v => moment.tz(v.split("T")[0], "YYYY-MM-DD", "America/New_York").format()
      },
      {
        property: "invoiceReceiptId",
        claimFieldTypeName: "INVOICE_NUMBER_TYPE",
        convert: v => v
      },
      { property: "total", claimFieldTypeName: "INVOICE_AMOUNT_TYPE", convert: v => v.toString() },
      {
        property: "receiverFirstName",
        claimFieldTypeName: "END_USER_FIRST_NAME_TYPE",
        convert: v => v
      },
      {
        property: "receiverLastName",
        claimFieldTypeName: "END_USER_LAST_NAME_TYPE",
        convert: v => v
      },
      {
        property: "receiverOrganizationName",
        claimFieldTypeName: "END_USER_ORGANIZATION_NAME_TYPE",
        convert: v => v
      },
      {
        property: "receiverPhone",
        claimFieldTypeName: "END_USER_PHONE_NUMBER1_TYPE",
        convert: v => v
      }
    ],
    addressToClaimFieldMappings: [
      {
        addressField: "address1",
        claimFieldTypeName: "END_USER_ADDRESS1_TYPE",
        convert: v => v
      },
      {
        addressField: "address2",
        claimFieldTypeName: "END_USER_ADDRESS2_TYPE",
        convert: v => v
      },
      {
        addressField: "city",
        claimFieldTypeName: "END_USER_CITY_TYPE",
        convert: v => v
      },
      {
        addressField: "region",
        claimFieldTypeName: "END_USER_REGION_TYPE",
        convert: v => v
      },
      {
        addressField: "country",
        claimFieldTypeName: "END_USER_COUNTRY_TYPE",
        convert: v => v
      },
      {
        addressField: "postalCode",
        claimFieldTypeName: "END_USER_POSTAL_CODE_TYPE",
        convert: v => v
      }
    ],
    ocrDocumentQueryToClaimFieldMappings: [
      {
        query: "What is the customer first name?",
        claimFieldTypeName: "END_USER_FIRST_NAME_TYPE",
        convert: v => v
      },
      {
        query: "What is the customer last name?",
        claimFieldTypeName: "END_USER_LAST_NAME_TYPE",
        convert: v => v
      },
      {
        query: "What is the customer company?",
        claimFieldTypeName: "END_USER_ORGANIZATION_NAME_TYPE",
        convert: v => v
      },
      {
        query: "What is the ship to address?",
        claimFieldTypeName: "END_USER_ADDRESS1_TYPE",
        convert: v => v
      },
      {
        query: "What is the ship to city?",
        claimFieldTypeName: "END_USER_CITY_TYPE",
        convert: v => v
      },
      {
        query: "What is the ship to state?",
        claimFieldTypeName: "END_USER_REGION_TYPE",
        convert: v => v
      },
      {
        query: "What is the ship to country?",
        claimFieldTypeName: "END_USER_COUNTRY_TYPE",
        convert: v => v
      },
      {
        query: "What is the ship to zip code?",
        claimFieldTypeName: "END_USER_POSTAL_CODE_TYPE",
        convert: v => v
      },
      {
        query: "What is the customer email?",
        claimFieldTypeName: "END_USER_EMAIL_TYPE",
        convert: v => v
      },
      {
        query: "What is the customer phone number?",
        claimFieldTypeName: "END_USER_PHONE_NUMBER1_TYPE",
        convert: v => v
      }
    ]
  }),
  computed: {
    ...mapGetters([
      "selectedParticipant",
      "claimFields",
      "claimUploads",
      "claimFieldValues",
      "claimProducts",
      "availableAddresses",
      "promotion",
      "claim"
    ]),
    isPublic() {
      return this.public;
    },
    hasAlert() {
      return this.$slots.alert?.length > 0;
    }
  },
  watch: {
    valid: {
      deep: true,
      immediate: true,
      handler(v) {
        this.$emit("validation", v.uploadForm);
      }
    },
    claimFields: {
      deep: true,
      immediate: true,
      handler(v) {
        this.localClaimFields = v;
      }
    },
    claimFieldValues: {
      deep: true,
      immediate: true,
      handler(v) {
        this.localClaimFieldValues = v;
      }
    },
    claimProducts: {
      deep: true,
      immediate: true,
      handler(v) {
        this.localClaimProducts = v;
      }
    },
    claimUploads: {
      deep: true,
      immediate: true,
      handler(v) {
        this.localClaimUploads = v;
      }
    },
    availableAddresses: {
      deep: true,
      immediate: true,
      handler(v) {
        this.localAvailableAddresses = v;
      }
    },
    localClaimFields: {
      deep: true,
      immediate: true,
      handler(v) {
        this.updateClaimFields(v);
      }
    },
    localClaimFieldValues: {
      deep: true,
      immediate: true,
      handler(v) {
        this.updateClaimFieldValues(v);
      }
    },
    localClaimProducts: {
      deep: true,
      immediate: false,
      handler(v) {
        this.updateClaimProducts(v);
      }
    },
    localClaimUploads: {
      deep: true,
      immediate: false,
      handler(v) {
        this.updateClaimUploads(v);
      }
    },
    localAvailableAddresses: {
      deep: true,
      immediate: true,
      handler(v) {
        this.updateAvailableAddresses(v);
      }
    }
  },
  methods: {
    ...mapActions([
      "updateClaimFieldValues",
      "updateClaimFields",
      "updateClaimUploads",
      "updateClaimProducts",
      "updateAvailableAddresses"
    ]),
    requireUpload() {
      if (this.claimUploads && this.claimUploads && this.claimUploads.existing) {
        if (this.claimUploads.existing.length > 0) {
          return true;
        } else {
          return "At least 1 upload is required";
        }
      } else {
        return false;
      }
    },
    showLoadingOcrInvoiceScan() {
      this.loadingOcrInvoiceScan = true;
      this.$forceUpdate();
    },
    ocrInvoice(upload) {
      if (this.$ocr.isOcrScanCancelled()) {
        return;
      }

      this.ocrPromise = this.$ocr
        .invoiceScan(this.promotion.id, upload.id, this.isPublic, upload.publicToken)
        .then(ocrResult => {
          if (this.$ocr.isOcrScanCancelled()) {
            return Promise.reject("OCR cancelled");
          }
          // I'm thinking about moving many of the following properties to a more general
          // instance like main.js but I'm not sure how yet.
          this.processOcrInvoiceScanResult(ocrResult);
          this.$root.$emit("ClaimFieldsProductsDocumentationEntryStep-validate");

          this.localAvailableAddresses = [];
          if (ocrResult && ocrResult.receiverAddress && ocrResult.receiverAddress.length > 0) {
            const promotionAddresses = ocrResult.receiverAddress.filter(address => address.trim() !== "");

            return promotionAddresses
              .reduce((prev, address) => {
                return prev.then(() => {
                  return this.$location.parseAddressForPromotion(address, this.promotion.id).then(data => {
                    this.localAvailableAddresses.push(data);
                  });
                });
              }, Promise.resolve())
              .then(() => {
                if (this.localAvailableAddresses && this.localAvailableAddresses.length > 0) {
                  // load the first address (which is receiver address)
                  this.loadAddress(this.localAvailableAddresses[0], true);
                }
                this.loadingOcrInvoiceScan = false;
                return Promise.resolve();
              });
          } else {
            this.loadingOcrInvoiceScan = false;
          }
        })
        .then(() => {
          this.$root.$emit("ClaimCompleteInformationEntryStep-validate");
          this.$emit("nextStep");
        })
        .catch(error => {
          console.log(error);
          this.loadingOcrInvoiceScan = false;
        });
    },

    onCancelOcrScan() {
      this.$ocr.cancelOcrScan();
      this.loadingOcrInvoiceScan = false;
      this.$emit("nextStep");
    },

    handleUploadError(errorMessage) {
      this.loadingOcrInvoiceScan = false;
      this.localClaimUploads.existing = [];
      this.apiErrors = { message: errorMessage };
      this.$forceUpdate();
      this.$emit("error", errorMessage);
    },

    loadAddress(address, initialLoad) {
      this.addressToClaimFieldMappings.forEach(mapping => {
        let claimField = this.findClaimFieldByClaimFieldType(this.localClaimFields, mapping.claimFieldTypeName);
        if (claimField) {
          this.$set(this.localClaimFieldValues, claimField.id, mapping.convert(address[mapping.addressField]));
          if (initialLoad) {
            claimField.ocrPopulated = true;
          }
        }
      });
      this.$forceUpdate();
    },
    findClaimFieldByClaimFieldType(claimFields, claimFieldTypeName) {
      let returnValue = null;
      claimFields.forEach(cf => {
        if (cf.claimFieldType.name == claimFieldTypeName) {
          returnValue = cf;
        }
      });
      return returnValue;
    },
    processOcrDocumentQueryResult(ocrResult, upload) {
      if (ocrResult.status == "READY" && this.ocrAttempts < this.ocrMaxAttempts) {
        let claimField;
        for (let answer in ocrResult.answers) {
          let answersList = ocrResult.answers[answer];
          let queryMapping = this.ocrDocumentQueryToClaimFieldMappings.find(elem => elem.query == answer);
          if (queryMapping) {
            claimField = this.findClaimFieldByClaimFieldType(queryMapping.claimFieldTypeName);
            if (claimField) {
              this.$set(this.localClaimFieldValues, claimField.id, queryMapping.convert(answersList[0]));
              this.$set(claimField, "ocrPopulated", true);
            }
          }
        }
        this.loadingOcrDocumentQuery = false;
      } else if (ocrResult.status == "PENDING" && this.ocrAttempts < this.ocrMaxAttempts) {
        this.ocrAttempts++;
        setTimeout(() => {
          this.$api.post("/api/ocr/checkDocumentQuery/" + this.promotion.id + "/" + upload.id).then(({ data }) => {
            this.processOcrDocumentQueryResult(data, upload);
          });
        }, 5000);
      } else if (this.ocrAttempts == this.ocrMaxAttempts) {
        this.loadingOcrDocumentQuery = false;
      }

      this.$forceUpdate();
    },
    processOcrInvoiceScanResult(ocrResult) {
      if (!ocrResult) return;
      for (let productLine of ocrResult.productLines) {
        this.localClaimProducts.push({
          promotionProduct: this.promotion.promotionProducts.find(
            pp => pp.product.productKey == productLine.productKey
          ),
          quantity: productLine.quantity ? productLine.quantity : 1,
          serialNumber: this.serialNumberConversion(productLine.serialNumber),
          ocrPopulated: true
        });
      }
      this.$forceUpdate();

      this.ocrInvoiceScanToClaimFieldMappings.forEach(mapping => {
        let claimField = this.findClaimFieldByClaimFieldType(this.localClaimFields, mapping.claimFieldTypeName);
        if (ocrResult[mapping.property] && claimField) {
          Vue.set(this.localClaimFieldValues, claimField.id, mapping.convert(ocrResult[mapping.property]));
          Vue.set(claimField, "ocrPopulated", true);
        }
      });
    },
    serialNumberConversion: serialNumber => {
      if (!serialNumber) return;
      let s01removed = serialNumber.replace(/^S01/gi, "");
      let cleanSerialNumber = s01removed.substring(0, Math.min(7, s01removed.length));
      return cleanSerialNumber;
    }
  }
};
</script>

<style></style>
