<template>
  <v-autocomplete
    v-model="selectedItem"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="totalItems"
    no-filter
    hide-no-data
    :item-text="render"
    item-value="id"
    clearable
    return-object
    :search-input.sync="search"
    :loading="loading"
    v-if="isAutocomplete"
  >
    <template v-slot:selected="{ item }">
      <slot name="selection" :item="item">
        {{ render(item) }}
      </slot>
    </template>
    <template v-slot:item="{ item }">
      <slot name="item" :item="item">
        {{ render(item) }}
      </slot>
    </template>
  </v-autocomplete>

  <v-combobox
    v-model="selectedItem"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="totalItems"
    no-filter
    :item-text="render"
    item-value="id"
    clearable
    return-object
    :search-input.sync="search"
    hide-no-data
    ref="combobox"
    :loading="loading"
    v-else
  >
    <template v-slot:selected="data">
      {{ render(data.item) }}
    </template>
    <template v-slot:item="data">
      {{ render(data.item) }}
    </template>

    <template v-slot:append-item>
      <v-list-item disabled v-if="showBottomMessage">
        <v-list-item-content>
          <v-list-item-title>
            Type to filter more records...
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item></template
    >
  </v-combobox>
</template>

<script>
import ApiService from "@/gapp-components/services/api.service";
import { mapGetters } from "vuex";

export default {
  name: "OrganizationField",
  props: {
    value: [Object, String],
    preventIds: Array,
    searchFilterJson: {
      type: Object,
      default: () => {},
      required: false
    },
    showKey: {
      type: Boolean,
      default: false,
      required: false
    },
    showLegacyKey: {
      type: Boolean,
      default: false,
      required: false
    },
    other: {
      type: Boolean,
      default: false,
      required: false
    },
    publicField: {
      type: Boolean,
      default: false,
      required: false
    },
    showCityRegionPostalCode: {
      type: Boolean,
      default: false,
      required: false
    },
    status: {
      type: String,
      required: false
    },
    isAutocomplete: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: () => ({
    debounceTimeout: null,
    showBottomMessage: true,
    selectedItem: null,
    loading: false,
    search: null,
    organizationTypes: null,
    extraItem: null,
    items: [],
    rules: [
      v => {
        if (!!v && !v.id) {
          return "Invalid selection";
        } else {
          return true;
        }
      }
    ]
  }),
  methods: {
    onInput(obj) {
      if (obj && obj.id) {
        this.$emit("input", obj);
      }
    },
    render(item) {
      let returnValue = "";
      if (item) {
        if (this.other && item.extraItem) {
          returnValue = item.name;
        } else {
          if (this.showKey) {
            returnValue += item.organizationKey ? item.organizationKey + " - " : "";
          } else if (this.showLegacyKey) {
            if (item.legacyOrganizationKey) {
              returnValue += item.legacyOrganizationKey + " - ";
            } else {
              returnValue += item.organizationKey + " - ";
            }
          }
          returnValue += item.name;
          if (this.showCityRegionPostalCode && item.id != "Other" && item.address) {
            returnValue += " - " + item.address.cityRegionPostalCountry;
          }
        }
      }

      return returnValue;
    },
    debouncedFetchItems(val) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.fetchData(val);
      }, 400);
    },
    fetchData(val) {
      if (this.loading) {
        return;
      }

      this.loading = true;
      let searchForm = {};

      let url = "/api/organizations/search";

      if (this.searchFilterJson) {
        searchForm = this.searchFilterJson;
      }

      if (this.publicField) {
        if (this.selectedProgram && !searchForm.programKey && !searchForm.programGroupKey) {
          searchForm.programKey = this.selectedProgram.programKey;
          searchForm.programGroupKey = this.selectedProgram.programGroup.programGroupKey;
        }
        url = url + "/public";
      }

      url = url + "?sort=name,ASC";
      searchForm.keyword = val;

      ApiService.post(url, searchForm)
        .then(({ data }) => {
          let fullItems = data.content;
          if (this.preventIds && this.preventIds.length > 0) {
            fullItems = fullItems.reduce((acc, cur) => {
              if (this.preventIds.indexOf(cur.id) == -1) {
                acc.push(cur);
              }
              return acc;
            }, []);
          }
          if (fullItems.length > 9) {
            this.items = fullItems.slice(0, 9);
            this.showBottomMessage = true;
          } else {
            this.items = fullItems;
            this.showBottomMessage = false;
          }
          if (this.other) {
            this.items.push({ id: "Other", name: "Other" });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  watch: {
    searchFilterJson: {
      handler(val, oldVal) {
        if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
          this.search = "";
          this.fetchData(this.search);
        }
      },
      deep: true
    },
    search(val) {
      this.debouncedFetchItems(val);
    },
    selectedItem(v) {
      if (v && v.id == "Other" && v.name == "Other") {
        this.$emit("otherSelected", { selectedItem: v, other: true });
      } else {
        if (v) {
          this.$emit("otherSelected", { selectedItem: v, other: false });
        } else {
          this.$emit("otherSelected", { selectedItem: undefined, other: false });
        }
      }
    },
    value: {
      immediate: true,
      handler(v) {
        if (!v) {
          this.selectedItem = null;
          this.extraItem = null;
          this.search = null;
          return;
        }
        if (v && v.id) {
          this.selectedItem = v;
        } else if (v) {
          let item = this.totalItems.some(elem => elem.name == v);
          if (this.other && !item && typeof v === "string" && v) {
            this.extraItem = { name: v, extraItem: true };
            item = this.extraItem;
          }
          this.selectedItem = item ? item : undefined;
          this.search = this.selectedItem ? this.selectedItem.name : null;
        }
      }
    }
  },
  mounted() {
    this.search = "";
  },
  computed: {
    ...mapGetters(["selectedProgram"]),
    totalItems() {
      const items = JSON.parse(JSON.stringify(this.items));
      if (this.extraItem) items.push(this.extraItem);
      return items;
    }
  }
};
</script>
