<template>
  <v-form v-model="valid" ref="mainForm">
    <v-container v-if="claimFields" :class="claimFieldsByRow.length > 0 ? 'pa-7' : 'pa-0'">
      <v-row v-for="(claimFieldRow, i) in this.claimFieldsByRow" :key="i">
        <template v-for="(claimField, j) in claimFieldRow">
          <v-col v-if="claimField.newCategory" cols="12" :key="j + '-category'" class="pa-0">
            <h4 class="grey lighten-2 pa-3 mb-3 rounded">{{ claimField.category }}</h4>
          </v-col>
          <v-col cols="12" sm="12" :md="claimField.cols" :lg="claimField.cols" :xl="claimField.cols" :key="j">
            <ClaimField
              dense
              outlined
              v-if="localClaimFieldValues && promotion"
              :background-color="claimField.ocrPopulated ? '#bbdefb' : null"
              :label="claimField.name"
              :hint="claimField.description"
              :promotion="promotion"
              :claimField="claimField"
              :publicField="isPublic"
              :error-messages="getValidationError(claimField.name)"
              v-model="localClaimFieldValues[claimField.id]"
              @input="clearValidationError(claimField.name)"
              @dateTimeChange="resetOcrPopulated(claimField)"
              @change="resetOcrPopulated(claimField)"
              @keydown="resetOcrPopulated(claimField)"
              @otherSelected="updateSonyDealerFieldsVisibility"
              :disabled="isDisabled(claimField)"
              :data-claimfield-id="claimField.id"
              :data-claimfield-name="claimField.name"
              :data-claimfield-type="claimField.claimFieldType.name"
            />
          </v-col>
        </template>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ClaimField from "@/gapp-components/components/fields/ClaimField.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { ClaimField },
  name: "InvoiceFieldsForm",

  props: {
    participantEmail: String,
    public: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    localClaimFieldValues: {},
    claimFieldsByRow: [],
    valid: false,
    errors: {}
  }),

  computed: {
    ...mapGetters(["claimFields", "promotion", "claimFieldValues", "invitationOnly", "isPublic"]),
    isPublic() {
      return this.public;
    }
  },

  watch: {
    participantEmail: {
      immediate: true,
      handler: "setClaimFieldEndUserEmailTemplateValue"
    },
    claimFields: {
      deep: true,
      immediate: true,
      handler: "setupClaimFields"
    },
    localClaimFieldValues: {
      deep: true,
      immediate: true,
      handler(v) {
        this.updateClaimFieldValues(v);
      }
    },
    claimFieldValues: {
      deep: true,
      immediate: true,
      handler(newVals) {
        for (const key in newVals) {
          if (!this.localClaimFieldValues[key] || this.localClaimFieldValues[key] !== newVals[key]) {
            this.$set(this.localClaimFieldValues, key, newVals[key]);
          }
        }
      }
    },
    valid: {
      immediate: true,
      handler(v) {
        this.$emit("validation", v);
      }
    }
  },
  created() {
    this.$root.$on("InvoiceFieldsForm-validate", () => {
      if (this.$refs.mainForm) {
        this.$refs.mainForm.validate();
      }
    });
  },
  methods: {
    ...mapActions(["updateClaimFieldValues"]),

    setClaimFieldEndUserEmailTemplateValue() {
      if (this.participantEmail && this.claimFields) {
        const found = this.claimFields.find(cf => cf.claimFieldType.name === "END_USER_EMAIL_TYPE");
        if (found) {
          this.localClaimFieldValues[found.id] = this.participantEmail;
        }
      }
    },

    setupClaimFields(claimFields) {
      if (!claimFields || !this.localClaimFieldValues) return;

      this.claimFieldsByRow = [];
      let rowCount = -1;
      let colCount = 0;

      claimFields
        .filter(claimField => !claimField.hidden)
        .forEach((claimField, i) => {
          claimField.cols = this.normalizeCols(claimField.cols);
          claimField.newCategory = i === 0 || claimField.category !== claimFields[i - 1].category;
          if (claimField.newCategory || colCount + claimField.cols > 12) {
            rowCount++;
            colCount = claimField.cols;
          } else {
            colCount += claimField.cols;
          }
          if (!this.claimFieldsByRow[rowCount]) {
            this.claimFieldsByRow[rowCount] = [];
          }
          this.claimFieldsByRow[rowCount].push(claimField);
          if (claimField.defaultValue && claimField.defaultValue.length > 0) {
            this.localClaimFieldValues[claimField.id] = claimField.defaultValue;
          }
          this.setClaimFieldEndUserEmailTemplateValue();
        });
    },

    normalizeCols(cols) {
      if (!cols || cols < 1 || cols > 12) {
        return 12;
      }
      return cols;
    },

    getValidationError(fieldName) {
      return this.$error.getValidationError(this.errors, fieldName);
    },

    clearValidationError(fieldName) {
      this.$error.clearValidationError(this.errors, fieldName);
    },

    resetOcrPopulated(claimField) {
      claimField.ocrPopulated = false;
    },

    isDisabled(claimField) {
      return (
        claimField.claimFieldType.name === "END_USER_EMAIL_TYPE" &&
        this.invitationOnly &&
        this.isPublic &&
        this.participantEmail &&
        this.participantEmail.length > 0
      );
    },

    updateSonyDealerFieldsVisibility(v) {
      const { selectedItem, other } = v;
      const claimFieldValuesToAssign = {};

      this.claimFields.forEach(claimField => {
        if (claimField.name?.startsWith("Sony Dealer") && claimField.name.trim() !== "Sony Dealer") {
          claimField.hidden = !other;

          if (!other && selectedItem) {
            this.assignSonyDealerFields(claimField, selectedItem, claimFieldValuesToAssign);
          } else if (other || !selectedItem) {
            claimFieldValuesToAssign[claimField.id] = null;
          }
        }
      });

      this.$set(this, "localClaimFieldValues", { ...this.localClaimFieldValues, ...claimFieldValuesToAssign });
      this.$forceUpdate();
    },

    assignSonyDealerFields(claimField, selectedItem, claimFieldValuesToAssign) {
      switch (claimField.name) {
        case "Sony Dealer Name":
          claimFieldValuesToAssign[claimField.id] = selectedItem.name;
          break;
        case "Sony Dealer Address 1":
          claimFieldValuesToAssign[claimField.id] = selectedItem.address?.address1 || selectedItem.address1;
          break;
        case "Sony Dealer Address 2":
          claimFieldValuesToAssign[claimField.id] = selectedItem.address?.address2 || selectedItem.address2;
          break;
        case "Sony Dealer City":
          claimFieldValuesToAssign[claimField.id] = selectedItem.address?.city || selectedItem.city;
          break;
        case "Sony Dealer State / Province":
          claimFieldValuesToAssign[claimField.id] = selectedItem.address?.region || selectedItem.region;
          break;
        case "Sony Dealer Country":
          claimFieldValuesToAssign[claimField.id] = selectedItem.address?.country || selectedItem.country;
          break;
        case "Sony Dealer Postal Code":
          claimFieldValuesToAssign[claimField.id] = selectedItem.address?.postalCode || selectedItem.postalCode;
          break;
      }
    }
  }
};
</script>
