<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="9" lg="9" xl="9" class="pa-7">
          <v-row v-if="['BASIC', 'OCR'].includes(this.mode)">
            <v-col cols="12">
              <v-card>
                <v-card-title primary-title>
                  Review Claim Details
                </v-card-title>
                <v-card-text>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-if="
                            selectedParticipant &&
                              selectedParticipant.participantType &&
                              selectedParticipant.participantType.allowClaimOnBehalfOf &&
                              participantSelectedToClaimOnBehalf
                          "
                        >
                          <th width="30%" class="text-right">
                            Participant selected to claim on behalf
                          </th>
                          <td width="70%">
                            {{ participantSelectedToClaimOnBehalf.fullName }}
                          </td>
                        </tr>
                        <tr
                          v-for="(claimField, i) in (claimFields ? claimFields : []).filter(
                            cf => !cf.hidden || (cf.name && cf.name.startsWith('Sony Dealer'))
                          )"
                          :key="i + '-values'"
                        >
                          <th width="30%" class="text-right">{{ claimField.name }}</th>
                          <td width="70%">
                            <span v-if="claimField.claimFieldType.date === true">
                              {{
                                claimFieldValues[claimField.id] && claimFieldValues[claimField.id].name
                                  ? claimFieldValues[claimField.id].name
                                  : claimFieldValues[claimField.id] | formatDateClient("MM/DD/YYYY", selectedClient)
                              }}
                            </span>
                            <span v-else-if="claimField.claimFieldType.name == 'INVOICE_AMOUNT_TYPE'">
                              {{ claimFieldValues[claimField.id] | toNumber(2) }}
                              {{ promotion.country ? promotion.country.currencyName : "" }}
                            </span>
                            <span
                              v-else-if="
                                claimField.claimFieldType.name == 'END_USER_REGION_TYPE' ||
                                  claimField.claimFieldType.name == 'REGION_TYPE'
                              "
                            >
                              {{ claimFieldValues[claimField.id] ? claimFieldValues[claimField.id].isoAlpha2 : "" }}
                            </span>

                            <span v-else-if="claimField.claimFieldType.name == 'UPLOAD_TYPE'">
                              <a
                                v-if="
                                  claimFieldValues[claimField.id] &&
                                    claimFieldValues[claimField.id].existing &&
                                    claimFieldValues[claimField.id].existing.length > 0
                                "
                                :href="claimFieldValues[claimField.id].existing[0].href"
                                target="_blank"
                                rel="noreferrer"
                                >{{ claimFieldValues[claimField.id].existing[0].originalFilename }}</a
                              >
                              ({{
                                claimFieldValues[claimField.id] &&
                                claimFieldValues[claimField.id].existing &&
                                claimFieldValues[claimField.id].existing.length > 0
                                  ? claimFieldValues[claimField.id].existing[0].contentType
                                  : ""
                              }})
                            </span>
                            <span v-else>
                              {{
                                claimFieldValues[claimField.id] && claimFieldValues[claimField.id].name
                                  ? claimFieldValues[claimField.id].name
                                  : claimFieldValues[claimField.id]
                              }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table></v-card-text
                >
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="['BASIC', 'OCR'].includes(this.mode)">
            <v-col cols="12">
              <v-card>
                <v-card-title primary-title>
                  Products
                </v-card-title>
                <v-card-text>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th width="30%" class="text-right">
                            Product
                          </th>
                          <th width="70%">
                            Details
                          </th>
                        </tr>
                        <tr v-for="(claimProduct, i) in claimProducts" :key="i + '-products'">
                          <td width="30%" class="text-right">
                            {{ claimProduct.promotionProduct.product.productKey }}
                          </td>
                          <td width="70%">
                            <span v-if="claimProduct.promotionProduct.enableSerialNumbers">
                              Quantity: 1, {{ $i18n.translate("Serial Number") }}: {{ claimProduct.serialNumber }}
                            </span>
                            <span v-else> Quantity: {{ claimProduct.quantity }}</span>
                            <span v-if="claimProduct.priceAmount">
                              , Price:
                              {{ claimProduct.priceAmount | toNumber(2) }}
                              {{ promotion.country ? promotion.country.currencyName : "" }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th width="30%" class="text-right">
                            Total Products Claimed
                          </th>
                          <td width="70%">
                            <span>{{ totalProductsClaimed }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table></v-card-text
                >
              </v-card>
            </v-col>
          </v-row>
          <v-row
            v-if="
              ['BASIC', 'OCR'].includes(this.mode) &&
                documentationRequired &&
                claimUploads &&
                claimUploads.existing &&
                claimUploads.existing.length > 0
            "
          >
            <v-col cols="12">
              <v-card>
                <v-card-title primary-title>
                  Supporting Documentation
                </v-card-title>
                <v-card-text
                  ><v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr v-for="(claimUpload, i) in claimUploads.existing" :key="i + '-uploads'">
                          <th width="30%" class="text-right">File {{ i + 1 }}</th>
                          <td width="70%">
                            <a :href="claimUpload.href" target="_blank" rel="noreferrer">{{
                              claimUpload.originalFilename
                            }}</a>
                            ({{ claimUpload.contentType }})
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table></v-card-text
                >
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="['ZERO_SALES'].includes(this.mode)">
            <v-col cols="12">
              <v-card>
                <v-card-title primary-title>
                  Zero Sales
                </v-card-title>
                <v-card-text
                  ><v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th width="30%" class="text-right">Month</th>
                          <td width="70%">
                            {{ formattedZeroSalesPeriod }}
                          </td>
                        </tr>
                        <tr>
                          <th width="30%" class="text-right">Sales</th>
                          <td width="70%">
                            $0
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table></v-card-text
                >
              </v-card>
            </v-col>
          </v-row>
          <v-row
            v-if="['BASIC', 'OCR'].includes(this.mode) && documentation.existing && documentation.existing.length > 0"
          >
            <v-col cols="12">
              <v-card>
                <v-card-title primary-title>
                  {{
                    strings.additionalDocumentationLabel
                      ? strings.additionalDocumentationLabel
                      : "Additional Documentation"
                  }}
                </v-card-title>
                <v-card-text
                  ><v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-for="(doc, i) in (claimUploads.existing ? claimUploads.existing : []).concat(
                            documentation.existing ? documentation.existing : []
                          )"
                          :key="i + '-documentation'"
                        >
                          <th width="30%" class="text-right">File {{ i + 1 }}</th>
                          <td width="70%">
                            <a :href="doc.href" target="_blank" rel="noreferrer">{{ doc.originalFilename }}</a> ({{ doc.contentType }})
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table></v-card-text
                >
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="grey lighten-3 pa-7">
          <h3 class="pb-2">
            <v-icon color="primary">mdi-information-outline</v-icon> Before you submit, please confirm:
          </h3>
          <ul>
            <li class="pt-2 pb-2">
              The invoice date is correct.
            </li>
            <li class="pt-2 pb-2">
              The invoice or document number is correct.
            </li>
            <li class="pt-2 pb-2">
              All SPIFF eligible products on the document were claimed.
            </li>
            <li class="pt-2 pb-2">
              The document proves the sale was completed during the promotional period.
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-row v-if="['BASIC', 'OCR'].includes(this.mode) && isOptInRequired">
        <v-col cols="12">
          <slot name="askForSubscriptionSlot" :updateOptIn="localUpdateOptIn" :optInValue="localOptIn">
            <v-card>
              <v-card-title primary-title>
                {{ $i18n.translate("Email communications") }}
              </v-card-title>
              <v-card-text>
                <p>
                  <v-checkbox
                    label="Yes, I would like to receive email that may be of interest to me."
                    v-model="optIn"
                  ></v-checkbox>
                </p>
              </v-card-text>
            </v-card>
          </slot>
        </v-col>
      </v-row>
      <v-row v-if="['BASIC', 'OCR'].includes(this.mode) && showTermsAndConditions">
        <v-col cols="12">
          <v-form @submit.prevent="onSubmit()">
            <TermsAndConditionsView
              :value="getTermsAndConditions"
              @update:valid="validTermsAndConditions = $event"
            ></TermsAndConditionsView>
          </v-form>
        </v-col>
      </v-row>
      <div class="text-center pa-7">
        <v-btn @click="$emit('previousStep')" text class="mr-2">{{ $i18n.translate("Back") }}</v-btn>
        <v-btn color="primary" @click="onSubmit()" :loading="isBusy">
          Submit
        </v-btn>

        <v-btn @click="$emit('cancel')" text>{{ $i18n.translate("Cancel") }}</v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TermsAndConditionsView from "@/gapp-components/components/display/TermsAndConditionsView";

export default {
  name: "ClaimDetailsStep",
  components: { TermsAndConditionsView },
  data: () => ({
    localOptIn: true,
    valid: false,
    validTermsAndConditions: false,
    terms: false,
    rules: {
      terms: [v => !!v || "Terms is required"]
    }
  }),
  props: {
    isOptInRequired: { type: Boolean, default: false, required: false },
    showTermsAndConditions: { type: Boolean, default: false, required: false }
  },
  methods: {
    ...mapActions(["updateOptIn"]),
    localUpdateOptIn(value) {
      this.localOptIn = value;
    },

    onSubmit() {
      this.$emit("submit");
    }
  },
  mounted() {},
  computed: {
    ...mapGetters([
      "selectedParticipant",
      "selectedClient",
      "selectedProgram",
      "selectedLocale",
      "mode",
      "claimFields",
      "claimFieldValues",
      "claimProducts",
      "claimUploads",
      "documentation",
      "formattedZeroSalesPeriod",
      "participantSelectedToClaimOnBehalf",
      "selectedZeroSalesPeriod",
      "promotion",
      "selectedAwardVehicle",
      "isBusy",
      "optIn",
      "flags",
      "strings",
      "documentationRequired"
    ]),

    totalProductsClaimed() {
      //if (!this.claimProducts) return 0;
      let amount = 0;
      for (let cp of this.claimProducts) {
        if (cp.quantity) {
          amount += eval(cp.quantity);
        } else {
          amount += 1;
        }
      }
      return amount;
    },
    getTermsAndConditions() {
      if (this.promotion.promotionType && this.promotion.promotionType.termsAndConditions.length > 0) {
        let termsAndConditions = this.promotion.promotionType.termsAndConditions.filter(
          tac => tac.language.name == this.selectedLocale.languageType.name
        );
        if (termsAndConditions.length > 0) {
          return termsAndConditions[0];
        }
      }
      return null;
    }
  },
  watch: {
    localOptIn: {
      deep: true,
      immediate: false,
      handler(v) {
        this.updateOptIn(v);
      }
    },
    optIn: {
      deep: true,
      handler(v) {
        this.localOptIn = v;
      }
    },
    "flags.optInDefaultValue": {
      deep: true,
      immediate: true,
      handler(v) {
        if (v !== null && v !== undefined) {
          this.localOptIn = v;
        }
      }
    }
  }
};
</script>

<style></style>
