/**
 * Generates validation rules for text fields with customizable error messages.
 * @param {string} fieldLabel - Optional. The label of the field to customize the error messages.
 * @param {number} maxLength - Optional. Maximum allowed length of the input.
 * @param {boolean} isRequired - Optional. field is required true or false.
 * @returns {Array} Array of validation functions.
 */

// Fixed maximum lengths that can never be exceeded
const MAX_TEXT_LENGTH = 100; // The absolute maximum length for text fields
const MAX_ADDRESS_LENGTH = 200; // The absolute maximum length for address fields

const ValidationService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },
  generateTextValidationRules(fieldLabel = "Input", inputMaxLength = MAX_TEXT_LENGTH, isRequired = true) {
    const effectiveMaxLength = inputMaxLength != null ? inputMaxLength : MAX_TEXT_LENGTH;

    let rules = [
      v => {
        if (v && v.trim().length > effectiveMaxLength) {
          return `${fieldLabel} must be less than ${effectiveMaxLength} characters.`;
        } else {
          return true;
        }
      }
    ];
    if (isRequired) {
      rules.push(v => (!!v && !!v.trim()) || `${fieldLabel} is required.`);
    }
    return rules;
  },
  generateAddressValidationRules(fieldLabel = "Address", inputMaxLength = MAX_ADDRESS_LENGTH, isRequired = true) {
    const effectiveMaxLength = inputMaxLength != null ? inputMaxLength : MAX_ADDRESS_LENGTH;
    let rules = [
      v => {
        if (v && v.trim()) {
          return (
            v?.trim().length <= effectiveMaxLength ||
            `${fieldLabel} must be less than ${effectiveMaxLength} characters.`
          );
        }
        return true;
      }
    ];
    if (isRequired) {
      rules.push(v => !!v?.trim() || `${fieldLabel} is required.`);
    }
    return rules;
  },
  generateEmailValidationRules(fieldLabel = "E-mail") {
    return [v => !!v?.trim() || `${fieldLabel} is required.`];
  },
  generateDropdownValidationRules(fieldLabel = "Selection") {
    return [v => (v && v !== "" && v.length !== 0) || `${fieldLabel} is required.`];
  },
  generateNotWhitespacesRule(fieldLabel = "Field") {
    return v => {
      if (v === "" || v === null || v === undefined) return true;
      return typeof v === "object" || v.trim().length > 0 || `${fieldLabel} cannot consist of only whitespaces.`;
    };
  },
  generateCheckboxValidationRules(fieldLabel = "Checkbox") {
    return [v => (Array.isArray(v) && v.length > 0) || `At lease one ${fieldLabel} must be checked.`];
  },
  generateDecimalValidationRules(fieldLabel = "Field", maxDecimals = 2, isRequired = true) {
    const regex = new RegExp(`^\\d*(\\.\\d{0,${maxDecimals}})?$`);
    let rules = [
      v => {
        if (isRequired && (v === "" || v === null || v === undefined)) {
          return `${fieldLabel} is required.`;
        }
        return (
          regex.test(v) || `${fieldLabel} must be a valid decimal number with up to ${maxDecimals} decimal places.`
        );
      }
    ];
    return rules;
  },
  generateIntegerValidationRules(fieldLabel = "Field", isRequired = true) {
    const integerRegex = new RegExp("^\\d*$");
    return [
      v => {
        if (isRequired && (v === "" || v === null || v === undefined)) {
          return `${fieldLabel} is required.`;
        }
        return v === null || integerRegex.test(v) || `${fieldLabel} must be a valid integer.`;
      }
    ];
  },

  generateDateValidationRules(fieldLabel = "Field", isRequired = true) {
    return [
      v => {
        if (isRequired && !v) {
          return `${fieldLabel} is required.`;
        }
        return true;
      }
    ];
  }
};

export default ValidationService;
