<template>
  <v-container>
    <v-row v-if="isPromotionExpired" class="text-center">
      <v-col>
        <v-card class="pa-5">
          <v-card-title class="display-1 text-uppercase text-center justify-center"
            >Sony rebate redemption</v-card-title
          >
          <v-card-text class="subtitle-1 text-uppercase mt-5 font-weight-bold">
            The Redemption Period for this Promotion has Expired.
          </v-card-text>
          <v-card-text class="mt-6">
            <div class="headline text-uppercase font-weight-bold">Need help?</div>
            <div class="pt-2">
              Contact us via email:
              <a href="mailto:ConsumerRebatesHQ@rebatesupport.com">ConsumerRebatesHQ@rebatesupport.com</a>
              or call <a href="tel:1-888-793-1332">1-888-793-1332</a>.
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col xs="12" sm="12" md="12" lg="12">
        <v-card v-if="showLandingPage && isAccessible">
          <v-toolbar flat>
            <v-toolbar-title class="headline">{{ promotion.name }}</v-toolbar-title>
            <v-spacer />
            <v-btn class="primary" @click="showLandingPage = false">Continue</v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row class="justify-center">
              <v-col lg="4" md="6" sm="12" xs="12" cols="auto">
                <v-card style=" display: flex !important; flex-direction: column;" class="cardStyle firstCard">
                  <v-card-title>Important Dates</v-card-title>
                  <v-card-text style="flex-grow: 1; overflow: auto;">
                    <div class="scrollable-table">
                      <v-data-table
                        :headers="[
                          {
                            text: this.$i18n.translate('Type'),
                            value: 'type',
                            align: 'right',
                            sortable: false
                          },
                          {
                            text: this.$i18n.translate('Date'),
                            value: 'date',
                            sortable: false
                          }
                        ]"
                        :items="importantDatesTableData"
                        class="elevation-1"
                        hide-default-footer
                        dense
                      >
                        <template v-slot:item.type="{ item }">
                          <b>{{ item.type }}</b>
                        </template>
                        <template v-slot:item.date="{ item }">
                          {{ item.date | formatDateClient("MM/DD/YYYY", selectedClient) }}
                        </template>
                      </v-data-table>
                    </div>
                  </v-card-text>
                  <v-card-text
                    class="text-center"
                    v-if="
                      promotion.promotionType && promotion.promotionType.promotionTypeKey != 'PROMOTION_IMAGING_EDGE'
                    "
                    style="padding: 16px;"
                  >
                    <img
                      alt="Acceptable UPC Codes"
                      style="max-width: 100%; height: auto;"
                      src="../../assets/acceptableUpcCodesV2.png"
                    />
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col lg="4" md="6" sm="12" xs="12" cols="auto">
                <v-row>
                  <v-col cols="12">
                    <v-card style=" display: flex !important;flex-direction: column;" class="cardStyle secondCard">
                      <v-card-title>Offer Details</v-card-title>
                      <v-card-text style="flex-grow: 1; overflow: auto;">
                        <div v-html="promotion.description"></div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>

              <v-col lg="4" md="12" sm="12" xs="12" cols="auto">
                <v-card class="cardStyle thirdCard" ref="container">
                  <v-card-title>
                    Promotion Payouts
                    <v-spacer />
                    <v-text-field
                      class="mr-2"
                      v-model="searchPromotionProduct"
                      :label="`${$i18n.translate('Search')} ${$i18n.translate('Promotion Product')}`"
                      flat
                      solo-inverted
                      hide-details
                      clearable
                      clear-icon="mdi-close-circle-outline"
                    />
                  </v-card-title>
                  <v-card-text>
                    <v-layout column>
                      <v-flex md6 style="overflow: auto">
                        <v-data-table
                          :search="searchPromotionProduct"
                          :loading="isLoading"
                          :headers="productPayoutHeaders"
                          :items="
                            selectedProgram.programKey != 'SONY-CEUR'
                              ? expandedProducts[promotion.id]
                              : sortedProductsAlphabetically(expandedProducts[promotion.id])
                          "
                          :no-data-text="$i18n.translate('No eligible products')"
                          class="elevation-1"
                          :options.sync="optionsPayouts"
                          :items-per-page="payoutTableItemsPerPage"
                          :footer-props="{ disableItemsPerPage: false, itemsPerPageOptions: [payoutTableItemsPerPage] }"
                          fixed-header
                          ref="dataTable"
                          :sortBy="selectedProgram.programKey != 'SONY-CEUR' ? [] : ['Category']"
                        >
                          <template v-slot:item.categoryName="{ item }">
                            {{ getCategoryName(item) }}
                          </template>
                        </v-data-table>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="primary" @click="showLandingPage = false">{{ $i18n.translate("Continue") }}</v-btn>
          </v-card-actions>
        </v-card>
        <template v-if="!isAccessible">
          <div class="white--text text-center">
            <p class="mb-5" />
            <h2>{{ restrictedPromotionMessage }}</h2>
            <p class="mb-5" />
          </div>
        </template>
        <ClaimForm
          id="claimForm"
          :promotionId="$route.params.id"
          v-if="!showLandingPage"
          @stepChange="$vuetify.goTo(0)"
          @onCancel="onCancel"
          :claimId="$route.query.claimId"
          :public="true"
          :enableOcr="true"
          :isOptInRequired="true"
          :showTermsAndConditions="true"
          publicParticipantTypeKey="950"
          :askForPurchaseDate="true"
          :askForPurchasePrice="true"
          :contentConfig="claimFormContentConfig"
          :forcedAwardVehicles="claimFormForcedAwardVehicles"
          :flags="flags"
          :strings="strings"
          :objects="objects"
          :rules="rules"
          :reminders="claimFormReminders"
          :showExampleImages="true"
        >
          <template v-slot:example-images>
            <v-img src="../../assets/all-sony-product-except-xperia.png" />
            <v-img v-if="selectedProgram.programKey != 'SONY-CEUR'" src="../../assets/for-xperia-only.png" />
          </template>

          <template v-slot:payout-instructions v-if="selectedProgram.programKey == 'SONY-CEUR'">
            <li>
              All Prepaid Cards will be mailed to your physical address
            </li>
          </template>

          <template v-slot:askForSubscriptionSlot="slotProps">
            <v-card>
              <v-card-title primary-title>
                {{ $i18n.translate("Sony email communications") }}
              </v-card-title>

              <v-card-text v-if="selectedCountry.name == 'USA' && selectedLocale.languageType.name == 'en'">
                <p>
                  <v-checkbox
                    :input-value="slotProps.optInValue"
                    label="Yes, I would like to receive email marketing communications from Sony Electronics Inc. about products and services that may be of interest to me."
                    @change="slotProps.updateOptIn($event)"
                  ></v-checkbox>

                  By checking the box, I agree to the Sony Electronics Inc
                  <a href="https://electronics.sony.com/privacy-policy" target="_blank" rel="noreferrer"
                    >Privacy Policy </a
                  >and certify that I am a U.S. resident.
                  <a href="https://electronics.sony.com/privacy-policy#DataPractices" target="_blank" rel="noreferrer"
                    >CA Privacy Notice</a
                  >
                </p>
              </v-card-text>
              <v-card-text v-if="selectedCountry.name == 'CAN' && selectedLocale.languageType.name == 'en'">
                <p>
                  <v-checkbox
                    :input-value="slotProps.optInValue"
                    label="Yes, I would like to receive marketing communications from Sony of Canada. Ltd. about products or services, promotions and events that may be of interest to me."
                    @change="slotProps.updateOptIn($event)"
                  ></v-checkbox>

                  I understand that I can unsubscribe at any time. For more information, please refer to the Sony of
                  Canada
                  <a href="https://corporate.sony.ca/view/privacy.htm" target="_blank" rel="noreferrer"
                    >Privacy Policy
                  </a>
                  or
                  <a href="https://corporate.sony.ca/view/contact_info.htm" target="_blank" rel="noreferrer"
                    >Contact Sony</a
                  >
                </p>
              </v-card-text>
            </v-card>
          </template>
        </ClaimForm>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ClaimForm from "@/gapp-components/components/forms/claim/ClaimForm.vue";
import PromotionService from "@/gapp-components/services/promotion.service.js";
import moment from "moment-timezone";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ClaimForm },
  name: "CeurClaimOcr",
  metaInfo() {
    return {
      title: this.$i18n.translate("Claim End User Rebate")
    };
  },
  data: () => ({
    step: "1",
    valid: {
      step1: false,
      step2: false,
      step3: false,
      step4: false
    },
    optionsPayouts: {
      sortBy: ["payout"],
      sortDesc: [true]
    },
    aboveRouterViewContentHeight: 0,
    payoutTableItemsPerPage: 10,
    expandedProducts: [],
    isBusy: false,
    isNew: false,
    isEditing: true,
    promotionId: 0,
    errors: {},
    serialNumberRegex: "",
    showLandingPage: true,
    promotion: {},
    isLoading: false,
    claimFields: [],
    claimFieldsByRow: [],
    claimFieldValues: {},
    defaultTermsAndConditions: [],
    selectedPromotionProducts: [],
    claimProducts: [],
    claimProductHeaders: [
      {
        value: "promotionProduct.product.productKey",
        text: "Model",
        align: "center"
      },
      {
        value: "serialNumber",
        text: "",
        sortable: false
      },
      {
        value: "actions",
        text: "Actions",
        align: "center",
        sortable: false
      }
    ],
    claimSubmitted: false,
    claimStage: null,
    claimUploads: {
      existing: [],
      deleted: []
    },
    localRules: {
      required: [v => !!v || "Field is required"],
      participant: [v => !!v || "Participant is required"],
      quantity: [v => !!v || "Quantity is required"],
      terms: [v => !!v || "Terms is required"]
    },
    form: {},
    uploads: {},
    uploadedFilesDialog: false,
    participantSelectedToClaimOnBehalf: null,
    claimNumber: "",
    q1: false,
    zeroSales: false,
    zeroSalesItems: [],
    selectedZeroSalesPeriod: null,
    formattedZeroSalesPeriod: null,
    saveEndUserForLater: false,
    selectedEndUser: null,
    availableAwardVehicles: [],
    participantSkipClaimDocumentation: false,
    promotionSkipClaimDocumentation: false,
    promotionTypeDocumentationRequired: false,
    supportingDocumentation: true,
    terms: false,
    examplesDialog: false,
    searchPromotionProduct: null,
    dialogImage: require("@/assets/sample-barcodes.png"),
    isAccessible: true,
    restrictedPromotionMessage: ""
  }),
  deactivated() {
    this.resetClaimState();
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.onBackButtonClick);

    this.resetClaimState();
  },
  created() {
    this.promotionId = parseInt(this.$route.params.id);
    if (this.$route.params.id == 0) {
      this.isNew = true;
    }
    this.participantSkipClaimDocumentation = false;
    this.promotionSkipClaimDocumentation = this.promotion.skipClaimDocumentation;
    this.restrictedPromotionMessage = this.$i18n.translate("This promotion is not accessible!");
    this.fetchData();
    this.loadZeroSalesMonths();
    this.$root.$on("backToLandingPage", () => {
      this.showLandingPage = true;
      history.replaceState({ backClicked: true }, null, null);
    });
  },
  mounted() {
    history.pushState({ backClicked: false }, null, null);

    window.addEventListener("popstate", this.onBackButtonClick);

    this.doAboveRouterViewContentHeightCalculation();
    if (this.aboveRouterViewContentHeight == 0) {
      setTimeout(() => this.doAboveRouterViewContentHeightCalculation(), 5000);
    }
    this.setPayoutTableItemsPerPage();
  },
  watch: {
    claimProducts: {
      handler() {
        this.$refs.step2Form.validate();
      },
      deep: true
    },
    claimUploads: {
      handler() {
        this.$refs.uploadForm.validate();
      },
      deep: true
    },
    selectedZeroSalesPeriod() {
      this.formattedZeroSalesPeriod = moment
        .tz(this.selectedZeroSalesPeriod, this.selectedClient.timezone)
        .format("MMMM YYYY");
    },
    step: {
      immediate: true,
      handler() {
        this.$forceUpdate();
      }
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram", "selectedClient", "selectedLocale", "selectedCountry"]),
    isPromotionExpired() {
      const promotionDeadlines = this.promotion.promotionDeadlines;
      const latestDeadline = PromotionService.findLatestSubmissionDeadline(promotionDeadlines);
      return latestDeadline && moment().isAfter(latestDeadline);
    },
    claimFormReminders() {
      return [
        {
          text:
            "For all Sony product, except Xperia, the middle-seven digits should be entered (e.g. S01-4036705-B -> 4036705)"
        },
        {
          text:
            this.selectedProgram?.programKey != "SONY-CEUR"
              ? "For Xperia product, the full IMEI is required (e.g. 35242794-019573-7)"
              : null
        }
      ];
    },
    claimFormForcedAwardVehicles() {
      return [{ awardVehicleKey: "EUR_PREPAID_AWARD_VEHICLE", name: "Single Load Debit Card" }];
    },
    claimFormContentConfig() {
      return {
        component: { styles: {} },
        mainStepper: { styles: { overflow: "visible" } },
        mainStepperHeader: {
          styles: {
            position: "sticky",
            top: this.aboveRouterViewContentHeight + "px",
            zIndex: 2,
            backgroundColor: "white"
          }
        }
      };
    },
    strings() {
      return {
        mask: "!S01-#######",
        additionalDocumentationLabel: "Upload additional documentation – UPC Codes and Purchase Receipts",
        claimProductsSerialnumberTooltipText:
          "Serial Numbers can be found on the outside of the original product packaging. Your sales invoices may also contain this detail. The middle -seven digits should be entered.",
        errorsAdditionalMessage:
          "Need Help? Contact Program Headquarters: <br>" +
          "Email: <a target='_blank' href='mailto:" +
          this.selectedProgram.programEmail +
          "'>" +
          this.selectedProgram.programEmail +
          "</a><br>" +
          "Phone: " +
          this.selectedProgram.programPhone +
          "<br><br>",
        prepaidCardsMessage:
          this.selectedProgram && this.selectedProgram.programKey == "SONY-CEUR" && this.selectedCountry.name == "CAN"
            ? "If potential earnings exceed $999 CAD, payments will be issued via multiple cards"
            : null,
        productListText:
          this.selectedProgram && this.selectedProgram.programKey == "SONY-CEUR"
            ? "Add products - select all that apply"
            : null
      };
    },
    flags() {
      return {
        claimProductsSerialnumberTooltip: true,
        includeClaimProductsSerialNumberTooltip: true,
        optInDefaultValue: false,
        markPurchasePriceAsRequired: this.selectedProgram && this.selectedProgram.programKey != "SONY-CEUR"
      };
    },
    objects() {
      return {
        tokens: {
          "#": { pattern: /\d/ },
          X: { pattern: /[0-9a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
          S: { pattern: /[a-zA-Z]/ },
          A: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
          a: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
          "!": { escape: true }
        }
      };
    },
    rules() {
      return {
        claimProductsPurchaseDate: [
          v => !!v || "Purchase Date is required",
          v =>
            this.$util.isEffective(this.promotion, moment(v)) ||
            "For assistance with claims processing outside of Purchase Dates contact 1.888.793.1332",
          v => moment(v).isBefore(moment()) || "Purchase Dates cannot be in the Future."
        ],
        claimProductsPriceAmount: this.selectedProgram && this.selectedProgram.programKey == "SONY-CEUR" ? [] : null
      };
    },

    isAdminOrPrincipalOwner() {
      //   return ["500", "510", "600", "610", "700", "710", "800", "810"].includes(
      //     this.selectedParticipant.participantType.participantTypeKey
      //   );
      return false;
    },
    productPayoutHeaders() {
      if (
        this.expandedProducts &&
        this.promotion &&
        this.expandedProducts[this.promotion.id] &&
        this.expandedProducts[this.promotion.id].every(elem =>
          elem.promotionProductPayouts.every(subelem => subelem.payoutType.name == "PRODUCT")
        )
      ) {
        return [
          { text: "Category", value: "categoryName", sortable: true },
          { text: this.$i18n.translate("Model"), value: "product.productKey", sortable: true }
        ];
      } else {
        return [
          { text: "Category", value: "categoryName", sortable: true },
          { text: this.$i18n.translate("Model"), value: "product.productKey", sortable: true },
          { text: this.$i18n.translate("Rebate"), value: "payout", sortable: true, align: "right" }
        ];
      }
    },
    importantDatesTableData() {
      const data = [];
      if (!(this.promotion && this.promotion.promotionDeadlines)) return [];
      this.promotion.promotionDeadlines.forEach(promotionDeadline => {
        if (promotionDeadline.submittable && this.isStillClaimable(promotionDeadline)) {
          data.push({
            type: this.$i18n.translate("Purchase Start Date"),
            date: promotionDeadline.effectiveDate ? promotionDeadline.effectiveDate : this.promotion.effectiveDate
          });
          data.push({
            type: this.$i18n.translate("Purchase End Date"),
            date: promotionDeadline.expirationDate ? promotionDeadline.expirationDate : this.promotion.expirationDate
          });
          if (this.promotion.promotionType.claimable) {
            data.push({
              type: this.$i18n.translate("Claim Deadline"),
              date: promotionDeadline.submissionDeadlineDate
            });
          }
        }
      });
      return data;
    },
    headers() {
      if (
        this.expandedProducts &&
        this.promotion &&
        this.expandedProducts[this.promotion.id] &&
        this.expandedProducts[this.promotion.id].every(elem =>
          elem.promotionProductPayouts.every(subelem => subelem.payoutType.name == "PRODUCT")
        )
      ) {
        return [{ text: this.$i18n.translate("Model"), value: "product.productKey", sortable: false }];
      } else {
        return [
          { text: this.$i18n.translate("Model"), value: "product.productKey", sortable: false },
          { text: this.$i18n.translate("Rebate"), value: "payout", sortable: false }
        ];
      }
    },
    totalProductsClaimed() {
      let amount = 0;
      for (let cp of this.claimProducts) {
        if (cp.quantity) {
          amount += eval(cp.quantity);
        } else {
          amount += 1;
        }
      }
      return amount;
    },
    termsAndConditions() {
      let selectedLocaleName = this.selectedLocale.languageType.name;
      if (this.promotion && this.promotion.promotionType && this.promotion.promotionType.termsAndConditions) {
        let promotionTypeTermsAndConditions = this.promotion.promotionType.termsAndConditions.filter(
          tac => tac.language.name == selectedLocaleName
        );
        if (promotionTypeTermsAndConditions.length > 0) {
          return promotionTypeTermsAndConditions[0];
        }
      }
      if (this.defaultTermsAndConditions) {
        let programTermsAndConditions = this.defaultTermsAndConditions.filter(
          tac => tac.language.name == selectedLocaleName
        );
        if (programTermsAndConditions.length > 0) {
          return programTermsAndConditions[0];
        }
      }

      return {
        description: "Terms And Conditions error. Not specified."
      };
    }
  },
  methods: {
    ...mapActions(["resetClaimState"]),
    doAboveRouterViewContentHeightCalculation() {
      let sumOfElementsHeights = 0;
      if (
        document &&
        document.querySelector(".v-app-bar") &&
        document.querySelector(".v-app-bar").getBoundingClientRect()
      ) {
        sumOfElementsHeights += document.querySelector(".v-app-bar").getBoundingClientRect().height;
      }
      if (
        document &&
        document.querySelector(".v-system-bar") &&
        document.querySelector(".v-system-bar").getBoundingClientRect()
      ) {
        sumOfElementsHeights += document.querySelector(".v-system-bar").getBoundingClientRect().height;
      }
      if (sumOfElementsHeights == 0) console.log("Error on aboveRouterViewContentHeight calculation");
      this.aboveRouterViewContentHeight = sumOfElementsHeights;
    },
    onBackButtonClick() {
      if (!this.showLandingPage) {
        this.showLandingPage = true;
        history.replaceState({ backClicked: true }, null, null);
      } else {
        history.go(-1);
      }
    },
    isStillClaimable(objectWithEffectivity) {
      let now = moment();
      let active = false;
      if (objectWithEffectivity.effectiveDate && objectWithEffectivity.submissionDeadlineDate) {
        active = now.isBetween(
          moment(objectWithEffectivity.effectiveDate),
          moment(objectWithEffectivity.submissionDeadlineDate)
        );
      } else if (objectWithEffectivity.effectiveDate) {
        active = now.isSameOrAfter(objectWithEffectivity.effectiveDate);
      } else if (objectWithEffectivity.submissionDeadlineDate) {
        active = now.isSameOrBefore(objectWithEffectivity.submissionDeadlineDate);
      } else {
        active = true;
      }
      return active;
    },
    setPayoutTableItemsPerPage() {
      let firstCard = document.querySelector(".firstCard");

      let secondCard = document.querySelector(".secondCard");

      let containerCard = document.querySelector(".cardStyle");

      let containerTitle = document.querySelector(".thirdCard .v-card__title");

      let containerHeader = document.querySelector(".thirdCard .v-data-table-header");

      let containerFooter = document.querySelector(".thirdCard .v-data-footer");

      let dataTable = this.$refs.dataTable;

      if (containerCard && containerTitle && dataTable) {
        let row = dataTable.$el.querySelector(".v-data-table .v-data-table__wrapper tbody tr");

        let singleRowHeight = row ? row.clientHeight : 48;

        // Logging each property used in the calculation
        let heightToConsider =
          firstCard.clientHeight > secondCard.clientHeight ? firstCard.clientHeight : secondCard.clientHeight;
        let containerHeight =
          heightToConsider - containerTitle.offsetHeight - containerHeader.offsetHeight - containerFooter.offsetHeight;

        let itemsThatCanFit = Math.floor(containerHeight / singleRowHeight);

        this.payoutTableItemsPerPage = itemsThatCanFit >= 5 ? itemsThatCanFit : 5;
      }
    },
    getProducts(promotion) {
      if (promotion.id in this.expandedProducts) return;
      this.isLoading = true;
      return this.$api
        .get(`/api/promotions/${promotion.id}/public`)
        .then(({ data }) => {
          const items = data.promotionProducts.map(item => ({
            ...item,
            mpgDescription: this.getMpgDescription(item),
            payout: this.calculatePayout(item.promotionProductPayouts)
          }));

          this.expandedProducts[promotion.id] = items;
          this.setPayoutTableItemsPerPage();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    calculatePayout(payouts) {
      if (!payouts || payouts.length === 0) return this.$i18n.translate("No Payout");

      const payout = payouts.find(p =>
        [
          "FLAT_AMOUNT_PER_UNIT_CLAIMED",
          "PERCENT_CLAIM_AMOUNT_CLAIMED",
          "PERCENT_PRODUCT_AMOUNT_CLAIMED",
          "PERCENT_CLAIM_PRODUCT_PRICE_AMOUNT_CLAIMED",
          "PRODUCT"
        ].includes(p.payoutType?.name)
      );

      if (!payout) return this.$i18n.translate("No Payout");

      const { name } = payout.payoutType || {};
      const payoutAmount = payout.payoutAmount;

      switch (name) {
        case "FLAT_AMOUNT_PER_UNIT_CLAIMED":
          return `$ ${Number(payoutAmount).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`;
        case "PERCENT_CLAIM_AMOUNT_CLAIMED":
        case "PERCENT_PRODUCT_AMOUNT_CLAIMED":
          return `${Number(payoutAmount).toFixed(2)} %`;
        case "PERCENT_CLAIM_PRODUCT_PRICE_AMOUNT_CLAIMED":
          return `${Number(payoutAmount).toFixed(2)} % per unit`;
        case "PRODUCT":
          return this.$i18n.translate("FREE Item");
        default:
          return this.$i18n.translate("No Payout");
      }
    },
    onNextStep() {
      switch (this.step) {
        case "1":
          if (!this.valid.step1) {
            this.$refs.step1Form.validate();
          } else {
            this.step = "2";
          }
          break;
        case "2":
          if (!this.valid.step2) {
            this.$refs.step2Form.validate();
          } else {
            this.step = "3";
          }
          break;
        case "3":
          if (!this.valid.step3) {
            this.$refs.uploadForm.validate();
          } else {
            this.step = "4";
          }
      }
    },
    checkDuplicatedSerialNumber(item) {
      let count = 0;
      this.claimProducts.forEach(element => {
        if (
          element.serialNumber &&
          element.promotionProduct.product.id == item.promotionProduct.product.id &&
          element.serialNumber == item.serialNumber
        ) {
          count++;
        }
      });
      if (count > 1) {
        return "Serial number duplicated";
      }
      return true;
    },
    requireSerialNumber(item) {
      if (item.promotionProduct.enableSerialNumbers) {
        if (item.serialNumber) {
          if (item.promotionProduct.product.serialNumberRegex) {
            let regex = RegExp("^" + item.promotionProduct.product.serialNumberRegex);
            if (regex.test(item.serialNumber)) {
              return true;
            } else {
              return "Invalid Serial Number";
            }
          } else {
            return true;
          }
        } else {
          return "Serial Number required";
        }
      } else {
        return false;
      }
    },
    requireProduct() {
      if (this.claimProducts.length > 0) {
        return true;
      } else {
        return "At least 1 product is required";
      }
    },
    requireUpload() {
      if (this.claimUploads.existing.length > 0 && !this.promotionSkipClaimDocumentation) {
        return true;
      } else {
        return "At least 1 upload is required";
      }
    },
    onAddProduct() {
      let promotionProducts = this.claimProducts;
      this.selectedPromotionProducts.some(promotionProduct => {
        let found = promotionProducts.findIndex(
          needle => needle.promotionProduct.product.id == promotionProduct.product.id
        );
        if (found == -1) {
          promotionProducts = promotionProducts.concat([{ promotionProduct: promotionProduct }]);
        } else {
          if (promotionProduct.enableSerialNumbers) {
            promotionProducts = promotionProducts.concat([{ promotionProduct: promotionProduct }]);
          }
        }
      });
      this.claimProducts = promotionProducts;
      this.selectedPromotionProducts = [];
    },
    onDeleteProduct(claimProduct) {
      const index = this.claimProducts.indexOf(claimProduct);
      this.claimProducts.splice(index, 1);
    },
    onDuplicateProduct(claimProduct) {
      let claimProductCopy = { ...claimProduct };
      this.claimProducts = this.claimProducts.concat(claimProductCopy);
    },
    onSubmit() {
      this.isBusy = true;
      this.errors = {};

      let postForm = {};
      postForm.promotionKey = this.promotion.promotionKey;
      // postForm.claimStage - allow this to be determined
      postForm.participantTypeKey = "950";
      postForm.participantStatus = { name: "ENR" };
      postForm.zeroSales = false;
      postForm.browserSource = window.location.href;
      postForm.browserReferer = document.referrer || this.getReferrerFromQueryString();
      postForm.uploads = this.claimUploads.existing;
      postForm.claimProducts = this.claimProducts;
      postForm.claimFieldValues = this.getClaimFieldValues();
      postForm.reuseParticipant = true;
      postForm.optIn = this.optIn;
      postForm.emailTemplateParticipantForm = {
        emailTemplateKey: "END_USER_CLAIM_SUBMISSION"
      };

      this.$api
        .postWithCaptcha("/api/claims/submit/public", postForm, null, "eurClaim")
        .then(({ data }) => {
          this.form = data;
          this.claimNumber = data.claimKey;
          this.isBusy = false;
          this.claimSubmitted = true;
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
          this.$vuetify.goTo(0);
          this.isBusy = false;
        });
    },
    getReferrerFromQueryString() {
      var queryString = window.location.search.substring(1);
      var params = new URLSearchParams(queryString);
      if (params.has("referrer")) {
        return params.get("referrer");
      } else {
        return null;
      }
    },
    getClaimFieldValues() {
      const claimFieldsClone = this.claimFields.slice();
      let cfValues = [];
      claimFieldsClone.map(claimField => {
        let cfValue = {
          claimField: { id: claimField.id },
          value:
            this.claimFieldValues[claimField.id] && this.claimFieldValues[claimField.id].name
              ? this.claimFieldValues[claimField.id].name.trim()
              : this.claimFieldValues[claimField.id]
              ? this.claimFieldValues[claimField.id].trim()
              : this.claimFieldValues[claimField.id]
        };
        cfValues.push(cfValue);
      });
      return cfValues;
    },
    fetchData() {
      this.isBusy = true;

      this.$api.get("/api/promotions/" + this.$route.params.id + "/public").then(({ data }) => {
        this.promotion = data.promotion;
        this.getProducts(this.promotion);
        this.defaultTermsAndConditions = data.programTermsAndConditions;
        this.supportingDocumentation = !this.promotion.skipClaimDocumentation;
        this.promotion.promotionProducts = data.promotionProducts.filter(item => item.effective == true);
        if (this.promotion.promotionAudienceType.name === "CLIENT_TPA") {
          this.isAccessible = false;
        }

        this.claimFields = data.claimFields.sort((a, b) => a.rankOrder - b.rankOrder);

        this.claimFieldsByRow = [];
        let rowCount = -1;
        let colCount = 0;

        this.claimFields.forEach((claimField, i) => {
          if (!claimField.cols || claimField.cols < 1 || claimField.cols > 12) {
            claimField.cols = 12;
          }
          if (i == 0 || this.claimFields[i].category != this.claimFields[i - 1].category) {
            claimField.newCategory = true;
          } else {
            claimField.newCategory = false;
          }
          if (claimField.newCategory || colCount + claimField.cols > 12) {
            rowCount++;
            colCount = claimField.cols;
          } else {
            colCount += claimField.cols;
          }
          if (!this.claimFieldsByRow[rowCount]) {
            this.claimFieldsByRow[rowCount] = [];
          }
          this.claimFieldsByRow[rowCount].push(claimField);
          if (claimField.defaultValue && claimField.defaultValue.length > 0) {
            this.claimFieldValues[claimField.id] = claimField.defaultValue;
          }
        });
        if (this.selectedParticipant) {
          if (this.selectedParticipant.optIn == undefined) {
            this.optIn = true;
          } else {
            this.optIn = this.selectedParticipant.optIn;
          }
        }

        if (this.promotion.uploads && this.promotion.uploads.length > 0) {
          this.promotion.uploads.forEach(upload => {
            if (upload.contentType.startsWith("image")) {
              Vue.set(this.promotion, "banner", upload);
            } else if (upload.contentType.startsWith("application")) {
              Vue.set(this.promotion, "download", upload);
            }
          });
        }

        this.isBusy = false;
      });
    },
    updateSonyDealerFieldsVisibility(v) {
      let selectedItem = v.selectedItem;
      let other = v.other;
      for (let claimField of this.claimFields) {
        if (claimField.name && claimField.name.startsWith("Sony Dealer") && claimField.name.trim() != "Sony Dealer") {
          claimField.hidden = !v.other;
          if (!other) {
            if (selectedItem) {
              switch (claimField.name) {
                case "Sony Dealer Name":
                  this.$set(this.claimFieldValues, claimField.id, selectedItem.name);
                  break;
                case "Sony Dealer Address 1":
                  this.$set(this.claimFieldValues, claimField.id, selectedItem.address1);
                  break;
                case "Sony Dealer Address 2":
                  this.$set(this.claimFieldValues, claimField.id, selectedItem.address2);
                  break;
                case "Sony Dealer City":
                  this.$set(this.claimFieldValues, claimField.id, selectedItem.city);
                  break;
                case "Sony Dealer State / Province":
                  this.$set(this.claimFieldValues, claimField.id, selectedItem.region);
                  break;
                case "Sony Dealer Country":
                  this.$set(this.claimFieldValues, claimField.id, selectedItem.country);
                  break;
                case "Sony Dealer Postal Code":
                  this.$set(this.claimFieldValues, claimField.id, selectedItem.postalCode);
                  break;
              }
            }
          }
          if (other || !selectedItem) {
            this.claimFieldValues[claimField.id] = null;
          }
        }
      }
      this.$forceUpdate();
    },
    getUploads(promotion) {
      return this.$api
        .get("/api/promotions/" + promotion.id + "/uploads")
        .then(({ data }) => {
          Vue.set(promotion, "uploads", data._embedded.uploads);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onCancel() {
      this.$router.push({ name: "eurList" });
    },
    sortedProductsAlphabetically(products) {
      if (products && products.length > 0) {
        let sortedProducts = products.slice().sort((a, b) => {
          const categoryComparison = a.mpgDescription.localeCompare(b.mpgDescription);
          if (categoryComparison === 0) {
            return a.product.productKey.localeCompare(b.product.productKey);
          } else {
            return categoryComparison;
          }
        });
        return sortedProducts;
      } else {
        return [];
      }
    },
    showUploadedFilesDialog() {
      this.$api
        .get("/api/promotions/" + this.$route.params.id + "/uploads/")
        .then(({ data }) => {
          this.uploads = data._embedded.uploads;
        })
        .then(() => {
          this.uploadedFilesDialog = true;
        });
    },
    formatSerialNumber(item) {
      if (item.serialNumber) {
        if (item.serialNumber.startsWith("S01")) {
          item.serialNumber = item.serialNumber.substring(3);
        }
        if (item.serialNumber.length < 7) {
          item.serialNumber = item.serialNumber.padStart(7, "0");
        }
      }
    },
    onClear() {
      this.participantSelectedToClaimOnBehalf = null;
    },
    backToDashboard() {
      this.$router.push({ name: "dashboard" });
    },
    onEndUserDelete(v) {
      if (v.id == this.selectedEndUser.id) {
        this.selectedEndUser = null;
      }
    },
    keyHandler(v) {
      if (v.key == " " || v.key == ",") {
        event.preventDefault();
      }
    },
    loadZeroSalesMonths() {
      this.zeroSalesItems = [];
      let month = moment();
      month.subtract(1, "months");
      for (let i = 0; i < 3; i++) {
        this.zeroSalesItems.push({
          text: month.format("MMMM YYYY"),
          value: month.format("YYYY-MM-[01]")
        });
        month.add(1, "months");
      }
    },
    getMpgDescription(item) {
      let description =
        item && item.product && item.product.productCategory && item.product.productCategory.name
          ? item.product.productCategory.name
          : "";
      if (description.startsWith("US ")) {
        return description.slice(3);
      }

      return description;
    },
    getCategoryName(item) {
      return item?.product?.productCategory
        ? item.product.productCategory.publicName || item.product.productCategory.name
        : "";
    }
  }
};
</script>
<style scoped>
.scrollable-div {
  max-height: 250px;
  min-height: 250px;
  overflow-y: auto;
}

.cardStyle {
  min-height: 550px;
  max-height: 550px;
  display: flex;
  flex-direction: column;
}

::v-deep #claimForm [data-claimfield-type="END_USER_EMAIL_TYPE"] .v-input__slot {
  background-color: #eeeeee;
}
::v-deep #claimForm .v-alert__content {
  color: #000000;
}

::v-deep #claimForm .v-alert__icon {
  color: #000000;
}
</style>
